import { cast, getSnapshot, Instance, types } from 'mobx-state-tree'
import { editableModel } from '../helpers/editable'
import moment from 'moment-timezone'
import { invoiceStatus } from '../dictionaries'
import numbro from 'numbro'
import { SupplierInvitationDetails } from './SupplierInvitationDetails'
import { CustomerAccount } from './CustomerAccount'
import { checkIfDateIsInFuture, checkIfValidDate } from '../helpers/validations'
import { Connector, EConnectorSyncType } from './Connector'
import { InvoicePayerInformation } from './InvoicePayerInformation'
import { ArAdvanceStatus } from '../dictionaries/factoring'

const dateFormat = 'MM/DD/YYYY'

export enum EInvoiceType {
  INVOICE = 'invoice',
  SALE_ORDER = 'sales_order',
  QUOTE = 'quote',
  STATEMENT = 'statement',
}

export enum EInvoiceNotificationType {
  EMAIL = 'EmailInvoice',
  SMS = 'TextInvoice',
  BOTH = 'Both',
}

export enum EInvoiceAddressType {
  EMPTY = '',
  DELIVERY = 'Delivery',
  PICKUP = 'Pickup',
  SERVICE = 'Service',
}

export enum InvoicePaymentType {
  FACTORING = 'Factoring',
  PAYNOW = 'PayNow',
}

export const QuoteDetails = types.model('QuoteDetails', {
  invoice_id: types.maybeNull(types.string),
  authorization_date: types.maybeNull(types.Date),
  authorization_deadline: types.maybeNull(types.Date),
  authorization_limit: types.maybeNull(types.number),
})

export const PaymentDetails = types.model('PaymentDetails', {
  paymentType: types.maybeNull(
    types.enumeration<InvoicePaymentType>(Object.values(InvoicePaymentType)),
  ),
  pricingPackageId: types.maybeNull(types.string),
  cardPricingPackageId: types.maybeNull(types.string),
  supplierFee: types.maybeNull(types.number),
  loanPlanId: types.maybeNull(types.string),
  originalDueDate: types.maybeNull(types.Date),
  arAdvanceStatus: types.optional(
    types.enumeration<ArAdvanceStatus>(Object.values(ArAdvanceStatus)),
    ArAdvanceStatus.NotApplied,
  ),
})

const cstDate = () => {
  const now = moment().tz('America/Chicago')
  return now.endOf('day').format(dateFormat)
}

const currencyFormat = (amount: number) =>
  numbro(amount).formatCurrency({ thousandSeparated: true, mantissa: 2 })

export const isImportedFromQuickBooks = (invoice: any) => {
  return (
    invoice.connector?.connector_type === 'QuickBooks' &&
    invoice.connector?.sync_type === EConnectorSyncType.TO_BLUE_TAPE
  )
}

export const Invoice = types
  .compose(
    types
      .model('Invoice', {
        id: '',
        company_id: types.optional(types.string, ''),
        type: types.optional(
          types.enumeration<EInvoiceType>(Object.values(EInvoiceType)),
          EInvoiceType.INVOICE,
        ),
        invoice_number: '',
        quote_number: types.maybeNull(types.string),
        operation_id: '',
        payer_id: types.optional(types.string, ''), // added for the builder created invoices
        customer_account_id: types.optional(types.string, ''),
        contacts: types.array(types.string),
        customer: types.maybeNull(CustomerAccount),
        supplierInvitationDetails: types.maybeNull(SupplierInvitationDetails), // for builder created invoices
        invoice_date: types.optional(types.string, cstDate),
        material_description: types.maybeNull(types.string),
        note: '',
        notes: types.optional(types.array(types.string), []),
        material_subtotal: types.optional(types.string, '0'),
        tax_amount: types.maybeNull(types.optional(types.string, '0')),
        refunded_amount: types.optional(types.string, ''),
        invoice_due_date: types.optional(types.string, cstDate),
        expiration_date: types.maybeNull(types.string),
        address: '',
        unitNumber: '',
        city: '',
        state: '',
        zip: '',
        lat: types.maybeNull(types.number),
        lng: types.maybeNull(types.number),
        addressType: types.optional(
          types.enumeration<EInvoiceAddressType>(
            Object.values(EInvoiceAddressType),
          ),
          EInvoiceAddressType.EMPTY,
        ),
        invoiceNotificationType: types.optional(
          types.enumeration<EInvoiceNotificationType>(
            Object.values(EInvoiceNotificationType),
          ),
          EInvoiceNotificationType.BOTH,
        ),
        invoice_document: types.maybeNull(types.string),
        document_name: types.maybeNull(types.string),
        status: types.optional(types.string, invoiceStatus.draft),
        isDeleted: types.optional(types.boolean, false),
        tax_exempted: types.optional(types.boolean, false),
        approved: types.optional(types.boolean, true),
        dismiss_reasons: types.maybeNull(types.array(types.string)),
        connector: types.optional(
          types.union(Connector, types.undefined),
          undefined,
        ),
        payersInfo: types.maybeNull(
          types.union(
            types.array(types.union(InvoicePayerInformation, types.string)),
          ),
        ),
        salesInfo: types.maybeNull(
          types.union(
            types.array(types.union(InvoicePayerInformation, types.string)),
          ),
        ),
        quoteId: types.maybeNull(types.string),
        project_id: types.maybeNull(types.string),
        seen: types.maybeNull(types.boolean),
        takenById: types.maybeNull(types.string),
        quoteDetails: types.maybeNull(QuoteDetails),
        paymentDetails: types.maybeNull(PaymentDetails),
        DEExecutionArn: types.maybe(types.string),
      })
      .views((self) => ({
        get isValidExpirationDate() {
          return (
            !self.expiration_date ||
            (!!self.expiration_date &&
              checkIfDateIsInFuture(
                self.expiration_date,
                self.invoice_due_date,
              ) &&
              checkIfDateIsInFuture(
                self.expiration_date,
                moment(self.invoice_date, dateFormat).isBefore(moment())
                  ? self.invoice_date
                  : moment().format(dateFormat),
              ))
          )
        },
        get isValidAmount() {
          return numbro(self.material_subtotal).value() !== 0
        },
        get isValidTotalAmount(): boolean {
          const totalAmount: number = self.tax_exempted
            ? numbro(self.material_subtotal || '0').value()
            : numbro(self.material_subtotal || '0').value() +
              numbro(self.tax_amount || '0').value()
          return totalAmount >= 1
        },
        get isValidInvoiceDate() {
          return (
            checkIfValidDate(self.invoice_date) &&
            moment(self.invoice_date, dateFormat).isAfter(
              moment().subtract(6, 'months'),
            )
          )
        },
        get isValidInvoiceDueDate() {
          return (
            (self.expiration_date
              ? checkIfDateIsInFuture(
                  self.expiration_date,
                  self.invoice_due_date,
                )
              : true) &&
            checkIfDateIsInFuture(
              self.invoice_due_date,
              moment(self.invoice_date, dateFormat).isBefore(moment())
                ? self.invoice_date
                : moment().format(dateFormat),
            )
          )
        },
      }))
      .views((self) => ({
        get isPristine() {
          return (
            self.id === '' &&
            self.company_id === '' &&
            self.invoice_number === '' &&
            self.operation_id === '' &&
            self.customer_account_id === '' &&
            self.material_description === '' &&
            self.note === '' &&
            self.material_subtotal === '0' &&
            self.invoice_date === moment().format(dateFormat) &&
            self.invoice_due_date === moment().format(dateFormat) &&
            self.expiration_date === '' &&
            self.address === '' &&
            self.addressType === '' &&
            self.invoice_document === null
          )
        },
        get isImportedFromQuickbooks() {
          return isImportedFromQuickBooks(getSnapshot(self))
        },
        get total_amount() {
          return currencyFormat(
            !self.tax_exempted
              ? numbro(self.material_subtotal || '0').value() +
                  numbro(self.tax_amount || '0').value()
              : numbro(self.material_subtotal || '0').value(),
          )
        },
        get canSave() {
          const requiredFields: Array<keyof typeof self> = [
            'customer_account_id',
            'invoice_number',
            'isValidAmount',
            'isValidTotalAmount',
            'isValidInvoiceDate',
            'isValidInvoiceDueDate',
          ]
          return (
            requiredFields.every((field) => !!self[field]) &&
            (!self.expiration_date || self.isValidExpirationDate) &&
            ([EInvoiceAddressType.PICKUP, EInvoiceAddressType.SERVICE].includes(
              self.addressType,
            ) ||
              (self.addressType === EInvoiceAddressType.DELIVERY &&
                self.address))
          )
        },
        get canSaveEngineerArchitect() {
          const requiredFields: Array<keyof typeof self> = [
            'invoice_number',
            'isValidAmount',
            'customer_account_id',
            'isValidInvoiceDate',
            'isValidInvoiceDueDate',
          ]
          if (!self.customer_account_id) return false
          return (
            requiredFields.some((field) => !!self[field]) &&
            (!self.expiration_date || self.isValidExpirationDate)
          )
        },
        get canSaveDraft() {
          const requiredFields: Array<keyof typeof self> = [
            'invoice_number',
            'customer_account_id',
          ]
          return requiredFields.every((field) => !!self[field])
        },
        get canSendInvoice() {
          const requiredFields: Array<keyof typeof self> = [
            'customer_account_id',
            'invoice_number',
            'isValidInvoiceDate',
            'isValidTotalAmount',
            'isValidInvoiceDueDate',
          ]
          return (
            requiredFields.every((field) => !!self[field]) &&
            (!self.expiration_date || self.isValidExpirationDate) &&
            ([EInvoiceAddressType.PICKUP, EInvoiceAddressType.SERVICE].includes(
              self.addressType,
            ) ||
              (self.addressType === EInvoiceAddressType.DELIVERY &&
                self.address))
          )
        },
      })),
    editableModel(),
  )
  .actions((self) => {
    return {
      setNotes(note: string) {
        self.notes = cast([...self.notes, note])
      },
      deleteNoteFromNotes(note: string) {
        self.notes = cast([...self.notes.filter((el) => el !== note)])
      },
    }
  })

export type IInvoiceModel = Instance<typeof Invoice>
