export enum ZohoBooksSalesOrderStrategy {
  NONE = 'None',
  AS_QUOTE = 'AsQuote',
  AS_INVOICE = 'AsInvoice',
}

export enum ZohoBooksQuoteType {
  NONE = 'None',
  ESTIMATE = 'Estimate',
  SALES_ORDER = 'SalesOrder',
}

export interface ICustomerFieldsSettings {
  blueTapeSyncMessage: number
  syncedSuccessfullyToBlueTape: number
  creditInfo: number
  customerType: number
}

export interface IUpdateZohoBooksSettings {
  customerFieldsSettings: ICustomerFieldsSettings
  creditMemo: boolean
  disableQuoteAuthorization: boolean
  disablePayNow: boolean
  arAdvanceCustomer: boolean
  salesOrderStrategy: ZohoBooksSalesOrderStrategy
  quoteType: ZohoBooksQuoteType
}

export interface IZohoBooksSettings {
  blueTapeApiAccountId: string
  blueTapeApiKey: string
  blueTapeApiUrl: string
  blueTapeApplicationUrl: string

  supportCreditMemo: boolean
  arAdvanceSupport: boolean

  quoteType: ZohoBooksQuoteType
  salesOrderStrategy: ZohoBooksSalesOrderStrategy
  disablePayNowFlow: boolean
  disableQuoteAuthorizationFlow: boolean
  customerFieldsMetaData: ICustomerFieldsSettings | null
}

export enum ZohoBooksInputFieldNames {
  AR_ADVANCE_CUSTOMER = 'arAdvanceCustomer',
  CREDIT_MEMO = 'creditMemo',
  QUOTE_TYPE = 'quoteType',
  SALES_ORDER_STRATEGY = 'salesOrderStrategy',
  DISABLE_PAY_NOW = 'disablePayNow',
  DISABLE_QUOTE_AUTHORIZATION = 'disableQuoteAuthorization',
  BLUE_TAPE_SYNC_MESSAGE = 'blueTapeSyncMessage',
  SYNCED_SUCCESSFULLY_TO_BLUETAPE = 'syncedSuccessfullyToBlueTape',
  CREDIT_INFO = 'creditInfo',
  CUSTOMER_TYPE = 'customerType',
}

export interface IZohoBooksFormData {
  [ZohoBooksInputFieldNames.AR_ADVANCE_CUSTOMER]: boolean
  [ZohoBooksInputFieldNames.CREDIT_MEMO]: boolean
  [ZohoBooksInputFieldNames.QUOTE_TYPE]: ZohoBooksQuoteType
  [ZohoBooksInputFieldNames.SALES_ORDER_STRATEGY]: ZohoBooksSalesOrderStrategy
  [ZohoBooksInputFieldNames.DISABLE_PAY_NOW]: boolean
  [ZohoBooksInputFieldNames.DISABLE_QUOTE_AUTHORIZATION]: boolean
  [ZohoBooksInputFieldNames.BLUE_TAPE_SYNC_MESSAGE]: string
  [ZohoBooksInputFieldNames.SYNCED_SUCCESSFULLY_TO_BLUETAPE]: string
  [ZohoBooksInputFieldNames.CREDIT_INFO]: string
  [ZohoBooksInputFieldNames.CUSTOMER_TYPE]: string
}
