import { post } from '../helpers/caller'
import { ActionPerformer } from '../dictionaries/actionPerformer'

export const quotes = {
  domain: 'quotes',

  postTransaction(quoteId: string, amount: number) {
    return post(quotes.domain, quotes.postTransaction, { quoteId, amount })
  },

  updateAuthorization(
    quoteId: string,
    authorizationDeadline: string,
    actionPerformer: ActionPerformer,
  ) {
    return post(quotes.domain, quotes.updateAuthorization, {
      quoteId,
      authorizationDeadline,
      actionPerformer,
    })
  },
}
