import {
  IGetAllRequest,
  IGetAllResponse,
  IGetResponse,
  IResponse,
  RouteBase,
} from '../types'
import {
  IInvoiceFilters,
  PaymentRequest,
  PricingPackageForIntegrationRequest,
  PricingPackageRequest,
  PricingPackageResponse,
  StatementRequest,
  StatementResponse,
} from './types'

export class Invoice extends RouteBase {
  domain = 'invoice'

  findAll(params: IGetAllRequest<IInvoiceFilters>) {
    if (params.project_id === null) {
      params = { ...params, project_id: '_NULL' }
    }

    return this.buildGetRoute<IGetAllResponse<any>>(
      this.findAll,
      params,
    ) as Promise<IGetAllResponse<any>>
  }

  findById(id: string) {
    return this.buildGetRoute<IGetResponse<any>>(this.findById, { id })
  }

  getPricingPackageFee(data: PricingPackageRequest) {
    return this.buildPostRoute<PricingPackageResponse>(
      this.getPricingPackageFee,
      data,
    )
  }

  getPricingPackageForIntegration(data: PricingPackageForIntegrationRequest) {
    return this.buildPostRoute<PricingPackageResponse>(
      this.getPricingPackageForIntegration,
      data,
    )
  }

  pay(data: PaymentRequest) {
    return this.buildPostRoute<IResponse>(this.pay, data)
  }

  statement(data: StatementRequest) {
    return this.buildGetRoute<StatementResponse>(this.statement, data)
  }

  getAllProjects(id: string) {
    return this.buildGetRoute<IGetResponse<any>>(this.getAllProjects, { id })
  }

  linkInvoice(data: { invoiceId: string }) {
    return this.buildPostRoute<IResponse>(this.linkInvoice, data)
  }

  checkInvoiceIsPaid(data: { invoiceId: string }) {
    return this.buildGetRoute<any>(this.checkInvoiceIsPaid, data)
  }

  showInvoiceIdle(data: {
    invoiceId?: string
    invoiceNumber?: string
    supplierCompanyId?: string
  }) {
    return this.buildGetRoute<any>(this.showInvoiceIdle, data)
  }
}

export default new Invoice()
