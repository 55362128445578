import { get, post } from '../helpers/caller'
import { IUpdateZohoBooksSettings, IZohoBooksSettings } from '../types'

export const zohoBooks = {
  domain: 'zohoBooks',

  authorize() {
    return get(zohoBooks.domain, zohoBooks.authorize)
  },

  connect(code: string) {
    return get(zohoBooks.domain, zohoBooks.connect, { code })
  },

  disconnect() {
    return get(zohoBooks.domain, zohoBooks.disconnect)
  },

  showSettings(): Promise<IZohoBooksSettings> {
    return get(zohoBooks.domain, zohoBooks.showSettings)
  },

  updateSettings(data: IUpdateZohoBooksSettings) {
    return post(zohoBooks.domain, zohoBooks.updateSettings, data)
  },

  isConnected() {
    return get(zohoBooks.domain, zohoBooks.isConnected)
  },
}
