export function parseNumber(input: any, defaultValue = 0): number {
  if (typeof input === 'number' && !isNaN(input)) {
    return input
  }

  if (typeof input === 'string') {
    // Remove non-numeric characters except dot and minus sign
    const cleaned = input.replace(/[^\d.-]/g, '')
    const number = Number(cleaned)

    return isNaN(number) ? defaultValue : number
  }

  return defaultValue
}
